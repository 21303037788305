import React from "react";
import { Link } from "gatsby";
import { Logo } from "../components/icons/Logo";

const Error: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container py-24 space-y-12 md:py-48 md:space-y-24">
        <div className="flex justify-center items-center">
          <Logo className="h-12" />
          <h1 className="ml-3 text-4xl font-bold">Vermessung Peitz</h1>
        </div>
        <div className="text-center space-y-12">
          <h2 className="text-6xl text-red-500">404 - Seite nicht gefunden</h2>
          <p>
            Die von Ihnen angefragte Seite wurde nicht gefunden. Bitte
            überprüfen Sie die URL und probieren Sie es erneut.
          </p>
          <Link to="/" className="text-primary font-bold underline">
            Zur Homepage
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Error;
