import React from "react";
import { IconProps } from "./common";

export const Logo: React.FC<IconProps> = (props) => {
  return (
    <div className={props.className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="100%"
        viewBox="0 0 24 24"
        strokeWidth="2"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M17 3l4 4l-14 14l-4 -4z"></path>
        <path d="M16 7l-1.5 -1.5"></path>
        <path d="M13 10l-1.5 -1.5"></path>
        <path d="M10 13l-1.5 -1.5"></path>
        <path d="M7 16l-1.5 -1.5"></path>
      </svg>
    </div>
  );
};
